
.privacyPolicyContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .privacyPolicyHeading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .privacyPolicyContent {
    margin-bottom: 20px;
  }
  
  /* Responsive Styles */
  
  @media screen and (max-width: 600px) {
    .privacy-policy-container {
      padding: 10px;
    }
  
    .privacy-policy-heading {
      font-size: 20px;
    }
  }
  