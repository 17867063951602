.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .productModal {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .productName {
    font-size: 24px;
    margin-bottom: 8px;
  }
  
  .productDescription {
    font-size: 16px;
    color: #888;
    margin-bottom: 16px;
  }
  
  .productPrice {
    font-size: 18px;
    margin-bottom: 16px;
  }
  
  .buyNowButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .buyNowButton:hover {
    background-color: #0056b3;
  }
  