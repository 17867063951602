.bibleSection {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url("../../images/bibleImg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bibleSection::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Change the color and opacity as desired */
  z-index: 1;
}

.bibleSection .container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  color: white;
}

.bibleSection .container .topContent,
.bibleSection .container .topContentTwo,
.bibleSection .container .inputContainer,
.bibleSection .container .displayContainer,
.bibleSection .container .submitButtonDiv,
.bibleSection .container .instructionsContainer {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.backButton {
  z-index: 10000;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.topContent {
  font-size: 22px;
  text-align: center;
  color: white;
  font-weight: 600;
}

.topContentTwo {
  font-size: 18px;
  text-align: center;
  color: white;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* margin-top: 30px; */
  color: white;
}

.inputContainer h3 {
  font-size: 20px;
  margin-bottom: 0px;
  color: white;
}

.inputContainer input[type="text"] {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  border-radius: 5px;
  border-color: black;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  font-size: 18px;
}

.displayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 25px;
}

.displayContainer h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #f3f3f3;
}

.displayBox {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 18px;
  height: 200px;
  overflow-y: auto;
  opacity: 0.7;
  color: black;
}

.displayBox > div {
  padding: 10px;
}

.submitButtonDiv {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

.submitButton {
  padding: 15px 20px;
  border: none;
  background-color: #964b00;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.3s ease;
}

.submitButton:hover {
  transform: translateY(-2px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.instructionsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.instructionsContainer h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.instructionsContainer p {
  margin: 0;
  margin-bottom: 10px;
}

.instructionsContainer aside {
  font-size: 12px;
}

.menuButton {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: url("../../images/hamburger.png") no-repeat center;
  background-size: contain;
  z-index: 1000;
}

.menu {
  position: fixed;
  top: 70px;
  right: 20px;
  width: 200px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  cursor: pointer;
}

.menu a {
  display: block;
  margin-bottom: 10px;
  color: #000;
  text-decoration: none;
}



@media only screen and (max-width: 550px) {
  .container {
    width: 90%;
  }

  .topContent {
    font-size: 18px;
    padding: 10px;
  }

  .topContentTwo {
    font-size: 14px;
    margin: 0;
  }

  .inputContainer h3 {
    font-size: 16px;
  }

  .inputContainer p{
    font-size: 14px;
  }

  .inputContainer input[type="text"] {
    max-width: 400px;
    font-size: 16px;
  }

  .displayContainer h3 {
    font-size: 18px;
  }

  .displayBox {
    margin-bottom: 20px;
    padding: 10px;
    height: 105px;
  }

  .submitButton {
    font-size: 16px;
    padding: 12px 16px;
  }

  .instructionsContainer aside {
    font-size: 12px;
  }

  .menuButton {
    top: 10px;
    right: 10px;
  }

  .menu {
    top: 50px;
    right: 10px;
  }
}
